import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ArrowRightIcon } from "lucide-react";
import DefaultLanguageInterface, { NewsItem } from "../../../interfaces";
import HomeNewsItem from "./HomeNewsItem/HomeNewsItem";
import { fetchHomeNews } from "../../../queries";
import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';
import SectionTitle from "../../../utils/SectionTitle/SectionTitle";
import SectionWrapper from "../../../utils/SectionWrapper/SectionWrapper";
import { Link } from "react-router-dom";
import DriverNewsItem from "../../Misc/News/DriverNewsItem/DriverNewsItem";
import EventNewsItem from "../../Misc/News/EventNewsItem/EventNewsItem";
import PressItem from "../../Press/PressItem/PressItem";
import { articles } from "../../Press/PressList/PressList";

const HomeNews: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const [hoverTitle, setHoverTitle] = useState<boolean>(false);
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const { data: newsItems = [] as NewsItem[], isLoading, error } = useQuery<NewsItem[], Error>({
        queryKey: ["recentNews"],
        queryFn: fetchHomeNews,
        staleTime: 5 * 60 * 1000
    });

    if (error) {
        console.error('Error fetching news:', error);
    }

    return (
        <SectionWrapper className="!px-0 pb-14" >
            <div className="flex justify-between w-full items-center select-none px-4">
                <SectionTitle title={languageData.home.home_news} />
                <div>
                    <a href="/news" className="duration-100 hidden sm:flex text-neutral-700 hover:text-main transition text-lg xmd:text-lg font-semibold items-center gap-2" onMouseOver={() => setHoverTitle(true)} onMouseLeave={() => setHoverTitle(false)}>
                        <div className="duration-100">{languageData.home.home_news_view_all}</div>
                        <ArrowRightIcon className={`duration-200 inline-block ${hoverTitle ? 'translate-x-0.5' : ''}`} />
                    </a>
                </div>
            </div>
            <div className="m-auto container-md px-6 flex flex-row gap-3 overflow-x-scroll scrollbar-hide snap-mandatory snap-x">
                <EventNewsItem />
                <DriverNewsItem />
                <div >
                    <PressItem loading={false}  {...articles[1]} activeLanguage={activeLanguage} />
                </div>
            </div>
            <div className="px-5">

                <div className="grid grid-cols-1 xmd:grid-cols-3 gap-4" >



                </div>
                <Link to="/news" className="duration-100 flex sm:hidden mt-2 text-neutral-700 hover:text-main transition text-lg xmd:text-lg font-semibold items-center gap-2" onMouseOver={() => setHoverTitle(true)} onMouseLeave={() => setHoverTitle(false)}>
                    <div className="flex items-center justify-center bg-neutral-100 hover:bg-neutral-200 px-2 py-1 rounded-full">
                        <div className="duration-100 text-sm">{languageData.home.home_news_view_all}</div>
                        <ArrowRightIcon className={`duration-200 h-3.5 mt-[1px] inline-block ${hoverTitle ? 'translate-x-0.5' : ''}`} />
                    </div>

                </Link>
            </div>
        </SectionWrapper>
    );
}

export default HomeNews;