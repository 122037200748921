import React, { useEffect, useRef, useState } from 'react';
import { CalendarIcon, ArrowUpIcon, ArrowDownIcon } from 'lucide-react';

import { ACTIVE_URL } from '../../../../constants';
import HomeNewsItem from '../../../Home/HomeNews/HomeNewsItem/HomeNewsItem';

import DefaultLanguageInterface from "../../../../interfaces";
import greekLanguage from '../../../../locales/gr.json';
import englishLanguage from '../../../../locales/en.json';
import SectionTitle from '../../../../utils/SectionTitle/SectionTitle';
import SectionWrapper from '../../../../utils/SectionWrapper/SectionWrapper';
import DriverNewsItem from '../DriverNewsItem/DriverNewsItem';
import PressList, {articles} from '../../../Press/PressList/PressList';
import EventNewsItem from '../EventNewsItem/EventNewsItem';
import PressItem from '../../../Press/PressItem/PressItem';

type NewsItem = {
    grTitle: string;
    enTitle: string;
    grDescription: string;
    enDescription: string;
    image: string;
    slug: string;
    date: string;
};

const NewsAllNews: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const [newsItems, setNewsItems] = useState<NewsItem[]>([{} as NewsItem, {} as NewsItem, {} as NewsItem]);
    const fetched = useRef<boolean>(false);
    const [loadingItems, setLoadingItems] = useState<boolean>(true);
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;
    const [currentFilter, setCurrentFilter] = useState<string>('descending_date');

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await fetch(`${ACTIVE_URL}/api/get_all_news/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setNewsItems([])
                    let temp_arr: any = [];
                    data.map((item: any) => {
                        if (item.slug in temp_arr) return;
                        setNewsItems((prev) => [...prev, {
                            grTitle: item.gr_title,
                            enTitle: item.en_title,
                            grDescription: item.gr_description,
                            enDescription: item.en_description,
                            image: item.image_data,
                            date: item.date,
                            slug: item.slug
                        }]);
                        temp_arr.push(item.slug);
                    })
                    setLoadingItems(false);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        }
        // Cleanup function
        if (!fetched.current) {
            fetchResponses();
            fetched.current = true;
        }
    }, []);

    useEffect(() => {
        const tempArr: any = [];
        newsItems.forEach((item, index) => {
            if (index === newsItems.length - 1) {
                if (tempArr.includes(item.slug)) {
                    setNewsItems(prev => prev.slice(0, -1));
                } else {
                    tempArr.push(item.slug);
                }
            }
        });
    }, [newsItems]);

    const sortItemsByDateAscending = () => {
        const sortedItems = [...newsItems].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        setNewsItems(sortedItems);
    }

    const sortItemsByDateDescending = () => {
        const sortedItems = [...newsItems].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        setNewsItems(sortedItems);
    }

    useEffect(() => {
        if (currentFilter === 'ascending_date') sortItemsByDateDescending();
        else if (currentFilter === 'descending_date') sortItemsByDateAscending();
    }, [currentFilter]);

    return (
        <SectionWrapper>
            <div className='flex flex-col xmd:flex-row w-full justify-between items-start gap-2 xmd:items-center mb-6'>
                <SectionTitle title={languageData.news.all_news_title} />
            </div>
            <div className="grid grid-cols-1 xmd:grid-cols-3 gap-4 mb-4" >
                <EventNewsItem />
                <DriverNewsItem />

                <PressItem loading={false}  {...articles[0]} activeLanguage={activeLanguage} />

            </div>
            <SectionWrapper className='flex flex-col gap-2 !px-0 !py-0'>
            <div className="grid grid-cols-1 xmd:grid-cols-3 gap-4" >
            <PressItem loading={false}  {...articles[1]} activeLanguage={activeLanguage} />
            <PressItem loading={false}  {...articles[2]} activeLanguage={activeLanguage} />
            <PressItem loading={false}  {...articles[3]} activeLanguage={activeLanguage} />
            </div>
            </SectionWrapper>
        </SectionWrapper>
    );
};

export default NewsAllNews;