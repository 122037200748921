import React, { useEffect } from 'react';
import DefaultLanguageInterface from '../../../interfaces';
import headerImg from '../../../images/events/1A.png';

const F1PressRelease: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div
                className="bg-no-repeat bg-cover flex pl-4 1240:pl-0 relative h-[680px] bg-[30%_30%] md:bg-[center_30%]"
                style={{ backgroundImage: `url(${headerImg})`}}
            >
                <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                <div className="max-w-1128 animate-fadeUp w-full h-full flex flex-col justify-end text-white m-auto xmd:justify-center relative z-10">
                    <div className='text-4xl font-semibold xmd:text-6xl animate-fadeUp xmd:pb-6 pb-5 leading-tight xmd:max-w-[763px]'>Lamborghini Taxi στους δρόμους της Αθήνας!</div>
                    <div className='delay-75 animate-fadeUp mb-4 xmd:mb-6 font-thin text-lg leading-8 w-full xmd:min-w-[400px] xmd:w-[32vw]'>
                        <p className='text-sm'>Εντυπωσιακό Event από τo taxi.gr</p>
                    </div>
                    <div className='button_div delay-150 animate-fadeUp scale-0.75 mb-[6vh]'>
                    </div>
                </div>
            </div>
            <div className='max-w-1128 m-auto pt-12 flex flex-col gap-4 pb-10 px-4  1180:px-0 xmd:pb-36 '>
                <h3 className='font-semibold text-lg mb-4'>Ζήσε την εμπειρία!</h3>
                <p>
                    To taxi.gr, το ελληνικό app μετακινήσεων που συνδέει οδηγούς ταξί με επιβάτες σε όλη την Αττική, διοργανώνει ένα μοναδικό και πρωτότυπο event που συναρπάζει τους λάτρεις της ταχύτητας και των supercars. Αυτό το τριήμερο, οι δρόμοι της Αθήνας γεμίζουν λάμψη, καθώς ένα Supercar, ειδικά διαμορφωμένο με τα λογότυπα της εταιρείας, προσφέρει εντυπωσιακές στιγμές στους χρήστες της πλατφόρμας και σε όσους κατεβάσουν το app μέχρι το τέλος του event!
                </p>
                <p>
                    Η Lamborghini, διάσημη για τις κορυφαίες επιδόσεις και το εκλεπτυσμένο της design, κυκλοφορεί στους δρόμους της πόλης, προσφέροντας στους Αθηναίους μια εμπειρία που θα μείνει αξέχαστη. Οι τυχεροί που επιλέγουν την εφαρμογή taxi.gr για τις μετακινήσεις τους, έχουν την ευκαιρία να απολαύσουν το θρυλικό Supercar από κοντά!
                </p>
                <p className='mb-4'>
                    Η Lamborghini, διακοσμημένη με τα ξεχωριστά αυτοκόλλητα του taxi.gr, ξεχωρίζει σε κάθε γωνιά της Αθήνας, καθιστώντας την εύκολα αναγνωρίσιμη από όλους. Αυτή η πρωτοβουλία εντάσσεται στο πλαίσιο της προώθησης των υπηρεσιών μας, αλλά και στην επιθυμία μας να προσφέρουμε μοναδικές στιγμές στους πελάτες μας.
                </p>
                <h3 className='font-semibold text-lg mb-4'>
                    Πώς μπορείτε να συμμετάσχετε;
                </h3>
                <p className='mb-4'>
                    Η διαδικασία είναι απλή!  Οι χρήστες της εφαρμογής taxi.gr μέχρι το βράδυ της Κυριακής επιλέγοντας την κατηγορία Supercar - Event στην εφαρμογή, έχουν την ευκαιρία να επιλεχθούν για μια μοναδική εμπειρία με τη Lamborghini. Μέσω του app επιλέγοντας την κατηγορία Supercar - Event οι χρήστες μπορούν να καλούν το Supercar, φυσικά αν το καλούν όταν βρίσκεται σε μικρή απόσταση αυξάνουν τις πιθανότητες να επιλεχθούν! Η εμπειρία απευθύνεται σε όλους, είτε είναι ήδη χρήστες της πλατφόρμας είτε την κατεβάσουν <a href='https://www.taxi.gr/app/website/passenger' className='text-main' target='_blank'>εδω</a>.
                </p>
                <h3 className='font-semibold text-lg mb-4'>
                    Ετοιμάσου να ζήσεις την εμπειρία!
                </h3>
                <p>
                    Η ομάδα του taxi.gr προσφέρει στους Αθηναίους και τους επισκέπτες της πόλης αυτή την μοναδική εμπειρία. Το event αποτελεί μέρος της δέσμευσής μας να καινοτομούμε συνεχώς και να προσφέρουμε νέες, συναρπαστικές υπηρεσίες στους χρήστες της εφαρμογής μας.
                </p>
                <p>
                    Μην χάσετε την ευκαιρία να ζήσετε την πολυτέλεια και τη δύναμη της Lamborghini με το taxi.gr!
                </p>
                <p className='mb-4'>
                    <a href='https://www.taxi.gr/app/website/passenger' target='_blank' className='text-main'>Συνδεθείτε</a> στην εφαρμογή μας και απολαύστε μια εμπειρία που δεν μοιάζει με καμία άλλη!
                </p>
                <h3 className='font-semibold text-lg mb-4'>
                    Ετοιμάσου να ζήσεις την εμπειρία!
                </h3>
                <p>Για κάλυψη του event ή περισσότερες πληροφορίες, επικοινωνήστε με την ομάδα event του taxi.gr στο <a href='mailto:support@taxi.gr' target='_blank' className='text-main'>support@taxi.gr</a></p>
            </div>
        </>
    )
}

export default F1PressRelease;