import { ChevronRightIcon } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import driverPageBg from '../../../../images/events/1A.png';

const EventNewsItem = () => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    return (
        <div className="shrink-0 grow-0 overflow-hidden basis-[calc(100vw-84px)] snap-always snap-center md:basis-[calc((100%-24px)/3)]">
            <Link to={'/supercar/event'} className="flex flex-col gap-2" onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                <div className="relative rounded-md overflow-hidden h-48">
                    <img
                        src={driverPageBg}
                        alt="Drive with taxi.gr"
                        className="absolute inset-0 w-full h-full object-cover"
                    />
                </div>
                <div className="flex flex-row gap-3">
                    <div className="w-4/5">
                        <h4 className="text-lg text-neutral-700 leading-[1.4rem]">Lamborghini Taxi στους δρόμους της Αθήνας!</h4>
                        <p className="text-xs text-neutral-600 mt-0.5">14/3/2025</p>
                    </div>
                    <div className="w-1/5 flex items-center justify-center">
                        <ChevronRightIcon className={`${isHovered ? 'ml-3 text-main' : 'text-neutral-600'} text-xl duration-200`} />
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default EventNewsItem;

